import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "internacionalizacao-i18n",
      "style": {
        "position": "relative"
      }
    }}>{`Internacionalização (i18n)`}</h1>
    <p>{`Os componentes do Bold usam por padrão a linguagem do browser para determinar o `}<em parentName="p">{`locale`}</em>{`.`}</p>
    <p>{`É possível customizar os textos usados pelos componentes usando `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`LocaleContext.Provider`}</code>{` e acessá-las através da `}<em parentName="p">{`hook`}</em>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useLocale`}</code>{`.`}</p>
    <p>{`A hook `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useLocale`}</code>{` retorna um mapa de palavras usadas internamente pelos componentes do bold, como o Calendar, Paginator etc.`}</p>
    <Demo src='pages/usage/i18n/LocaleProvider' mdxType="Demo" />
    <h2 {...{
      "id": "locales-disponiveis",
      "style": {
        "position": "relative"
      }
    }}><em parentName="h2">{`Locales`}</em>{` disponíveis`}</h2>
    <p>{`Verifique os `}<em parentName="p">{`locales`}</em>{` disponíveis na pasta `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`src/i18n/locales`}</code>{`. Sinta-se livre para abrir um `}<em parentName="p">{`pull request`}</em>{` caso seu `}<em parentName="p">{`locale`}</em>{` não esteja presente.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      